import React from "react";
import { Link } from "gatsby";

import Checkbox from "@material-ui/core/Checkbox";

export default function LoginPhone({ setshowemail }) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="signup loginphone form">
      <h1>Login</h1>
      <h3 className="switch-way">
        Login with <span onClick={() => setshowemail(true)}>Email</span>
      </h3>

      <div className="formgroup">
        <label>PHONE NUMBER</label>
        <input className="" placeholder="+1 201-555-10123" />

        <div className="agreeterms">
          <Checkbox
            defaultChecked
            value="secondary"
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <span>
            I have accept
            <Link to=""> Terms of Service.</Link>
          </span>
        </div>
        <Link to="signup/verify">
          <button className="next">Next</button>
        </Link>
        <h3>
          New user?
          <Link to="/signup"> Register</Link>
        </h3>
      </div>
    </div>
  );
}
